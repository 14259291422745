export const initialStorage = {
  applicantId: null,
  accountStatus: null,
  accountType: null,
  dicDraft: {},
  registerEmail: '',
  email_trade_confirmation_consent: false,
  ekyc_consent: false,
  toc_consent: false,
  applicantInfo: {},
  brokerData: null,
  activeIndex: 0,
  indexApplicant: 0,
  equixId: null,
  applicantOther: null,
  isSubApplicant: false,
  addressData: {},
  all_application_submitted: false,
  isOperatorSupport: false,
  isSubmitted: false
}

const dataStorage = {
  enviroment: 'UAT',
  userType: 0,
  addressKey: '',
  baseUrl: '',
  config: {},
  configAll: {},
  refreshToken: null,
  tokenRefresh: null,
  refreshTokenId: null,
  accessToken: null,
  deviceId: '',
  setError: null,
  theme: 'light',
  listEnv: ['dev1', 'uat', 'equix', 'morrison', 'ricard', 'equitystorytrader', 'optixtrading'],
  listDraft: [],
  paginateDraft: {},
  ...initialStorage
}
export default dataStorage
